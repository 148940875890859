import { API_URL } from './../app.config';
import { HttpService } from './http.service';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Subject, timer } from 'rxjs';
import { retry, share, switchMap, takeUntil, tap } from 'rxjs/operators';
import { OrdersAuditlogComponent } from '../module/view-audit/orders-auditlog/orders-auditlog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class SharedService implements OnDestroy {
  private stopPolling = new Subject();
  online: boolean;
  constructor(private httpService:HttpService,private matDialog: MatDialog, private http: HttpClient,) {
    this.online = true;
     }

/**
 * @name getAllStatesAndDistricts
 * @description API call to get list of all states and districts with respective links.
 * @params isContractors boolean to include contractors
 * @returns object; 
 */
  getAllStatesAndDistricts(isContractors?){
    let accessToken = JSON.parse(window.localStorage['okta-token-storage']);
    let url = API_URL.getAllStatesAndDistricts.replace('{personalNumber}',accessToken.idToken.claims.Employee_ID);
    //let url = API_URL.getAllStatesAndDistricts.replace('{personalNumber}',"");
    url = isContractors?url+"?isContractor="+isContractors:url;
    return this.httpService.apiRequest(url,'GET');
  }

  getAllStatesAndDistrictsValidator(isContractors?){
    let accessToken = JSON.parse(window.localStorage['okta-token-storage']);
    let url = API_URL.getAllStatesAndDistrictsValidator;
    //let url = API_URL.getAllStatesAndDistricts.replace('{personalNumber}',"");
    url = isContractors?url+"?isContractor="+isContractors:url;
    return this.httpService.apiRequest(url,'GET');
  }

  getAllMeterManufactures(){
    let accessToken = JSON.parse(window.localStorage['okta-token-storage']);
    let url = API_URL.getAllMeterManufactures;
    return this.httpService.apiRequest(url, 'GET');
  }


  /**
   * @name getTabsCount
   * @description polling api retus an observarble of the count of the tabs
   * @returns obserable
   */
   getTabsCount(){
    this.stopPolling.next();
    const url = API_URL.getTabsCount;
    const url2 = API_URL.bannerData +'W1VMGMT';
   return timer(1, 60000).pipe(
      switchMap(()=>{
        let confstr = localStorage.getItem('pending_config');
        let data
        if(confstr){
        let conf = JSON.parse(confstr)
        data ={
          districts:conf.config.listOfSelectedDistrict.length>0?conf.config.listOfSelectedDistrict:[],
          companies:conf.config.listOfSelectedCompany.length>0?conf.config.listOfSelectedCompany:[]
        }
      }
      else{
        data={
          districts:[],
          companies:[]
        }
      }
        const tabs = this.httpService.apiRequest(url,'POST',data);
        const banner = this.httpService.apiRequest(url2,'GET');
        return forkJoin([tabs,banner]);
      }),
      retry(2),
      share(),
      takeUntil(this.stopPolling)
    );
  }
  ngOnDestroy() {
    this.stopPolling.next();
}
 /**
  * 
  * @param type type of module log requested
  * @param id Id of logs requested
  * @param options extra param
  * @returns List of all audit logs history of logs 
  */
  getAuditDetails(type,id,options?){
    let url = API_URL.getAuditTrialLogs;
    //let url = API_URL.getAuditTrialLogs + "?exceptionId=" + exceptionId;
    url = url.replace("{auditType}",type);
    url = url.replace("{id}", id);
    return this.httpService.apiRequest(url, 'GET');
  }
  /**
   * 
   * @param workOrderNumber Order number history
   * @returns list of audit logs for requested work order number
   */
  getOrdersAuditLog(workOrderNumber){
    let url = API_URL.getWorkOrderLog.replace("{workOrderNumber}", workOrderNumber);
    return this.httpService.apiRequest(url, 'GET');
}

/**
 * 
 * @param workOrderNumber Open work OrderModal
 */

openAuditLogsModal(workOrderNumber){
  this.getOrdersAuditLog(workOrderNumber).subscribe((response)=>{
    console.log(response);
    this.matDialog.open(OrdersAuditlogComponent, {
      panelClass: 'audit-container',
      data:response,
      width:'80vw',
      height: 'auto',
      maxHeight: '80vh',
    });
  },(error)=>{
    console.log(error);
  })
}
/**
 * 
 * @param orders List of data to sort
 * @returns return default sort based 
 */
defaultSortWorkOrder(orders,currentTab){

  let compareState,compareDistrict,comparePriority,compareAssignmentStart,compareDueDate;
  let sortedOrdersList = orders.sort((b, a) => {
    if(b.state && a.state){
      compareState = b.state.localeCompare(a.state);
      compareDistrict = b.district.localeCompare(a.district);
      comparePriority = Number(b.priority) - Number(a.priority);
    }
    switch (currentTab) {
      case 'pending':
        return compareState || compareDistrict || comparePriority;
      case 'monitor':
        if (b?.assignmentStart && a?.assignmentStart) {
          compareAssignmentStart = b.assignmentStart.localeCompare(a.assignmentStart);
        }
        return compareState || compareDistrict || comparePriority || compareAssignmentStart;
      case 'openOrder':
        if (b?.dueDate && a?.dueDate) {
          compareDueDate = b.dueDate.localeCompare(a.dueDate);
        }
        return compareState || compareDistrict || comparePriority || compareDueDate;
      default:
        return compareState || compareDistrict || comparePriority;
    }
   });
   return sortedOrdersList;
 }
 validateMiu(miuno){
  let accessToken = JSON.parse(window.localStorage['okta-token-storage']);
  const headerOptions = {
    "Authorization":accessToken.accessToken.accessToken,
    "APIKey":environment.w1vApi.apikey
  };
  const headers = new HttpHeaders(headerOptions);
  let url = API_URL.miuValidators.replace("{miuno}", miuno);
  return this.httpService.apiRequest(url, 'GET',null,{headers:headers});
 }
 updateBannerData(data){
  let url = API_URL.updateBanners
    return this.httpService.apiRequest(url, 'PUT',data);
 }
 getBannerData(appName,type){
  let url = API_URL.getBannerDataByAppname.replace("{app}",appName).replace("{type}",type)
    return this.httpService.apiRequest(url, 'GET');
 }

 updateThresholdData(data){
  let url = API_URL.getConfigValues
    return this.httpService.apiRequest(url, 'PUT', data);
 }

 getThresholdData(){
  let url = API_URL.getConfigValues + "?name=ControlTeamThreshold"
  return this.httpService.apiRequest(url, 'GET');
 }

 fetchDetails(id: String, deviceLocation: String, device: string, district?: string, manufacturer?: string) {
  let url;
  let accessToken = JSON.parse(window.localStorage['okta-token-storage']);
  const headerOptions = {
    "Authorization":accessToken.accessToken.accessToken,
    "APIKey":environment.w1vApi.apikey
  };
  const headers = new HttpHeaders(headerOptions);
  if (device === 'meter' && district && manufacturer) {
    url =
      API_URL.getMeterDetails_scanner
      + "&district=" + district + "&meterManufacturer=" + manufacturer + "&meterSerialNumber=" + id;
  }
  else if(device === 'meter'){
    url =
    API_URL.getMeterDetails_mapcall +
    '&actionCode=I&MSN=' +
    id +
    '&deviceLocation=' +
    deviceLocation;
  }
  else {
    url =
      API_URL.getMIUDetails_mapcall +
      '&actionCode=I&MSN=' +
      id +
      '&deviceLocation=' +
      deviceLocation;
  }
  return this.httpService.apiRequest(url, 'GET',null,{headers:headers});
}

 /**
  * @name timeFromNow
  * @description returns the humanized version of time from now 
  * @param dateTime Moment Object
  * @returns a string of type ex(1d 2h 3m 5s ago)
  */
 timeFromNow(dateTime:moment.Moment) {
  
  const mil = moment().diff(dateTime);
  console.log(mil);
  const duration = moment.duration(mil, 'milliseconds');
  const durationAsDays = Math.floor(duration.asDays());
  const durationAsHours = Math.floor(
    duration.subtract(durationAsDays, 'days').asHours()
  );
  const durationAsMinutes = Math.floor(
    duration.subtract(durationAsHours, 'hours').asMinutes()
  );
  const durationAsSeconds = Math.floor(
    duration.subtract(durationAsMinutes, 'minutes').asSeconds()
  );

  let relativeDuration = '';

  if (durationAsDays > 0) relativeDuration += durationAsDays + 'd ';

  if (durationAsHours > 0) relativeDuration += durationAsHours + 'h ';

  if (durationAsMinutes > 0) relativeDuration += durationAsMinutes + 'm ';

  if (durationAsSeconds > 0) relativeDuration += durationAsSeconds + 's ago';
    
  return relativeDuration
  
}
}
