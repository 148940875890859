<!-- <div class="miu-main-container"> -->
  <!-- <div class="back-btn" (click)="navigateback()"><img src="assets/images/header/back-icon.png" /></div> -->
  <!-- <div class="container"> -->
    <div class="header-wrapper-validator" mat-dialog-title>
      <div class="validator-header"><img src="/assets/icons/search.svg" /> Inventory Checker</div>
      <button mat-icon-button class="close-button headerclose" mat-dialog-close>
        <mat-icon class="close-icon " color="primary">close</mat-icon>
      </button>
    </div>
    <mat-tab-group>
      <mat-tab label="Meter">
        <div class="miufield">
          <div class="meter-field-all" style="display: flex;">
            <mat-drawer #drawer opened="true" class="left-side-content" mode="side"
              style="max-height: 432px; overflow-y: scroll;width: '248' !important;">
              <app-state-district-selector [mode]="'single'" outputFormat="district"
                [inputFormat]="{type:'string',key:''}" [dataSource]="allStatesAndDistricts"
                (onSelection)="stateDistrictSelectionChange($event)"></app-state-district-selector>
            </mat-drawer>

            <div class="meter-input-fields">
              <mat-form-field class="validator-form-field" [ngClass]="{'manufacturer-selected': manufacturerSelected }">
                <mat-label [ngClass]="{'manufacturer-selected': manufacturerSelected }"
                  *ngIf="manufacturerSelected === ''" class="meter-select-label">Select Meter Manufacturer</mat-label>
                <mat-select [(ngModel)]="manufacturerSelected" [disableOptionCentering]="true" panelClass="myPanelClass"
                  class="mat-select-validator">
                  <mat-option *ngFor="let meterManufacturer of meterManufactures" [value]="meterManufacturer.value"
                    class="option-container">
                    {{meterManufacturer.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="fieldtitle"><span class="meterNumberText"> If a Meter number is </span><span
                  class="notValidatedText">not validated </span> <img src="/assets/icons/validationstatus/error.svg" /><span
                  class="notvalid">, do not use.</span></div>
              <div class="input-grid">
                <div *ngFor="let index of[0, 1, 2, 3, 4, 5]; let i = index" class="input-row">
                  <div class="input-column" *ngFor="let col of [0, 1, 2]; let j = index">
                    <input #userMeterInput class="meterinputfield" type="text" id="meterInput{{i * 3 + j}}" placeholder="Meter Number"  (keypress)="alphaNumericMeter($event,(i * 3 + j))"
                      [(ngModel)]="meterNumbers['field' + (i * 3 + j)]" (focus)="isFocused[i * 3 + j] = true"
                      (blur)="isFocused[i * 3 + j] = false"
                      [ngClass]="{'pass': meterNumbers['field' + (i * 3 + j)] &&  validMeterSerial.indexOf(meterNumbers['field' + (i * 3 + j)]) >= 0, 'fail': meterNumbers['field' + (i * 3 + j)] &&  invalidMeterSerial.indexOf(meterNumbers['field' + (i * 3 + j)]) >= 0}" />
                    <label for="meterInput{{i * 3 + j}}" class="meter-label"
                      [ngClass]="{'labelPass': meterNumbers['field' + (i * 3 + j)] &&  validMeterSerial.indexOf(meterNumbers['field' + (i * 3 + j)]) >= 0, 'labelFail': meterNumbers['field' + (i * 3 + j)] &&  invalidMeterSerial.indexOf(meterNumbers['field' + (i * 3 + j)]) >= 0}"><b>
                        {{meterMap.get(i * 3 + j)[0]}}</b> {{meterMap.get(i * 3 + j)[1]}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btnfield">
            <button class="clear" (click)="clearAllMeter()">Clear All</button>
            <button class="validate-meter" [disabled]="!manufacturerSelected || selectedDistrict == null || isMeterArrayPopulated()"
              (click)="validateMeters(meterNumbers)">Validate</button>
          </div>
          <div class="btn-buffer"></div>
        </div>
      </mat-tab>
      <mat-tab label="MIU">
        <div class="miufield">
          <div class="fieldtitle"> Type or Scan MIU Numbers Below. <span *ngIf="invalidSerial.length > 0">If an MIU
              number is <span class="notvalid">not validated <img src="/assets/icons/validationstatus/error.svg" /></span>, isolate
              and do not use.</span></div>
          <input type="text" class="inputfield" #userInput id="1" (keypress)="alphaNumeric($event,1)"
            [(ngModel)]="miuNumber.field1" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field1 &&  validSerial.indexOf(miuNumber.field1) >= 0, 'fail': miuNumber.field1 &&  invalidSerial.indexOf(miuNumber.field1) >= 0}" />
          <input type="text" class="inputfield" #userInput id="2" (keypress)="alphaNumeric($event,2)"
            [(ngModel)]="miuNumber.field2" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field2 &&  validSerial.indexOf(miuNumber.field2) >= 0, 'fail': miuNumber.field2 &&  invalidSerial.indexOf(miuNumber.field2) >= 0}" />
          <input type="text" class="inputfield" #userInput id="3" (keypress)="alphaNumeric($event,3)"
            [(ngModel)]="miuNumber.field3" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field3 &&  validSerial.indexOf(miuNumber.field3) >= 0, 'fail': miuNumber.field3 &&  invalidSerial.indexOf(miuNumber.field3) >= 0}" />
          <input type="text" class="inputfield" #userInput id="4" (keypress)="alphaNumeric($event,4)"
            [(ngModel)]="miuNumber.field4" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field4 &&  validSerial.indexOf(miuNumber.field4) >= 0, 'fail': miuNumber.field4 &&  invalidSerial.indexOf(miuNumber.field4) >= 0}" />
          <input type="text" class="inputfield" #userInput id="5" (keypress)="alphaNumeric($event,5)"
            [(ngModel)]="miuNumber.field5" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field5 &&  validSerial.indexOf(miuNumber.field5) >= 0, 'fail': miuNumber.field5 &&  invalidSerial.indexOf(miuNumber.field5) >= 0}" />
          <input type="text" class="inputfield" #userInput id="6" (keypress)="alphaNumeric($event,6)"
            [(ngModel)]="miuNumber.field6" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field6 &&  validSerial.indexOf(miuNumber.field6) >= 0, 'fail': miuNumber.field6 &&  invalidSerial.indexOf(miuNumber.field6) >= 0}" />
          <input type="text" class="inputfield" #userInput id="7" (keypress)="alphaNumeric($event,7)"
            [(ngModel)]="miuNumber.field7" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field7 &&  validSerial.indexOf(miuNumber.field7) >= 0, 'fail': miuNumber.field7 &&  invalidSerial.indexOf(miuNumber.field7) >= 0}" />
          <input type="text" class="inputfield" #userInput id="8" (keypress)="alphaNumeric($event,8)"
            [(ngModel)]="miuNumber.field8" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field8 &&  validSerial.indexOf(miuNumber.field8) >= 0, 'fail': miuNumber.field8 &&  invalidSerial.indexOf(miuNumber.field8) >= 0}" />
          <input type="text" class="inputfield" #userInput id="9" (keypress)="alphaNumeric($event,9)"
            [(ngModel)]="miuNumber.field9" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field9 &&  validSerial.indexOf(miuNumber.field9) >= 0, 'fail': miuNumber.field9 &&  invalidSerial.indexOf(miuNumber.field9) >= 0}" />
          <input type="text" class="inputfield" #userInput id="10" (keypress)="alphaNumeric($event,10)"
            [(ngModel)]="miuNumber.field10" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field10 &&  validSerial.indexOf(miuNumber.field10) >= 0, 'fail': miuNumber.field10 &&  invalidSerial.indexOf(miuNumber.field10) >= 0}" />
          <input type="text" class="inputfield" #userInput id="11" (keypress)="alphaNumeric($event,11)"
            [(ngModel)]="miuNumber.field11" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field11 &&  validSerial.indexOf(miuNumber.field11) >= 0, 'fail': miuNumber.field11 &&  invalidSerial.indexOf(miuNumber.field11) >= 0}" />
          <input type="text" class="inputfield" #userInput id="12" (keypress)="alphaNumeric($event,12)"
            [(ngModel)]="miuNumber.field12" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field12 &&  validSerial.indexOf(miuNumber.field12) >= 0, 'fail': miuNumber.field12 &&  invalidSerial.indexOf(miuNumber.field12) >= 0}" />
          <input type="text" class="inputfield" #userInput id="13" (keypress)="alphaNumeric($event,13)"
            [(ngModel)]="miuNumber.field13" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field13 &&  validSerial.indexOf(miuNumber.field13) >= 0, 'fail': miuNumber.field13 &&  invalidSerial.indexOf(miuNumber.field13) >= 0}" />
          <input type="text" class="inputfield" #userInput id="14" (keypress)="alphaNumeric($event,14)"
            [(ngModel)]="miuNumber.field14" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field14 &&  validSerial.indexOf(miuNumber.field14) >= 0, 'fail': miuNumber.field14 &&  invalidSerial.indexOf(miuNumber.field14) >= 0}" />
          <input type="text" class="inputfield" #userInput id="15" (keypress)="alphaNumeric($event,15)"
            [(ngModel)]="miuNumber.field15" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field15 &&  validSerial.indexOf(miuNumber.field15) >= 0, 'fail': miuNumber.field15 &&  invalidSerial.indexOf(miuNumber.field15) >= 0}" />
          <input type="text" class="inputfield" #userInput id="16" (keypress)="alphaNumeric($event,16)"
            [(ngModel)]="miuNumber.field16" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field16 &&  validSerial.indexOf(miuNumber.field16) >= 0, 'fail': miuNumber.field16 &&  invalidSerial.indexOf(miuNumber.field16) >= 0}" />
          <input type="text" class="inputfield" #userInput id="17" (keypress)="alphaNumeric($event,17)"
            [(ngModel)]="miuNumber.field17" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field17 &&  validSerial.indexOf(miuNumber.field17) >= 0, 'fail': miuNumber.field17 &&  invalidSerial.indexOf(miuNumber.field17) >= 0}" />
          <input type="text" class="inputfield" #userInput id="18" (keypress)="alphaNumeric($event,18)"
            [(ngModel)]="miuNumber.field18" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field18 &&  validSerial.indexOf(miuNumber.field18) >= 0, 'fail': miuNumber.field18 &&  invalidSerial.indexOf(miuNumber.field18) >= 0}" />
          <input type="text" class="inputfield" #userInput id="19" (keypress)="alphaNumeric($event,19)"
            [(ngModel)]="miuNumber.field19" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field19 &&  validSerial.indexOf(miuNumber.field19) >= 0, 'fail': miuNumber.field19 &&  invalidSerial.indexOf(miuNumber.field19) >= 0}" />
          <input type="text" class="inputfield" #userInput id="20" (keypress)="alphaNumeric($event,20)"
            [(ngModel)]="miuNumber.field20" placeholder="MIU Number"
            [ngClass]="{'pass': miuNumber.field20 &&  validSerial.indexOf(miuNumber.field20) >= 0, 'fail': miuNumber.field20 &&  invalidSerial.indexOf(miuNumber.field20) >= 0}" />
          <div class="btnfield">
            <button class="clear" (click)="clearAll()">Clear All</button>
            <button class="validate" (click)="validate(miuNumber)" [disabled]="isMIUArrayPopulated()">Validate</button>
          </div>
          <div class="btn-buffer"></div>
        </div>
      </mat-tab>
    </mat-tab-group>

  <!-- </div>
</div> -->