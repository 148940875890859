import { Component, ElementRef, OnInit, ViewChildren, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { OrdersService } from '../../services/orders.service';
import * as _ from 'lodash';
import { LoaderService } from '../../services/loader.service';
import { ToastService } from 'src/app/services/toast.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { MatSelect } from '@angular/material/select';
import { AlertsComponent } from '../alerts/alerts.component';

interface MeterManufacturer {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-miu-validator',
  templateUrl: './miu-validator.component.html',
  styleUrls: ['./miu-validator.component.scss']
})
export class MiuValidatorComponent implements OnInit {
  @ViewChild(MatSelect) matSelect!: MatSelect;
  fieldCount = 20;
  miuNumber: any = {};
  meterNumbers: any = {};
  invalidSerial: string[] = [];
  validSerial: string[] = [];
  validMeterSerial: string[] = [];
  invalidMeterSerial: string[] = [];
  meterMap = new Map();
  private overlayRef!: OverlayRef;
  @ViewChildren('userInput') userInput;
  @ViewChildren('userMeterInput') userMeterInput;
  allStatesAndDistricts: any;
  allMeterManufactures: any;
  selectedDistrict: any;
  @Output() stateDistrictSelectionChanged = new EventEmitter<any>();
  @Input() currentTab;
  @Input() mode;
  locationConfiguration = {
    addNew: false,
    list: <any>[],
    selected: null,
    temp: null,
  };
  KEY_ENTER_ASCII_VAL = 13;
  KEY_NUMBER_RANGE_MIN = 48;
  KEY_NUMBER_RANGE_MAX = 57;

  isFocused: boolean[] = new Array(18).fill(false);
  meterManufactures: MeterManufacturer[] = [
  ];
  meterManufacturess: string[] = ['Badger', 'Neptune'];
  manufacturerSelected: string = "";
  constructor(
    public ordersService: OrdersService,
    public toasterSevice: ToastService,
    private loader: LoaderService,
    private router: Router,
    public dialog: MatDialog,
    private overlay: Overlay,
    private sharedService: SharedService,
  ) {
    for (let i = 0; i < this.fieldCount; i++) {
      this.meterMap.set(i, ["", "Enter Meter Number"]);
    }


  }

  ngOnInit(): void {
    this.initlizeData();
  }

  initlizeData() {
    this.loader.showLoader();
    const calls = this.sharedService.getAllStatesAndDistrictsValidator(false);
    //calls[0] = ;
    forkJoin(calls).subscribe((response) => {
      let tabdataObject;
      let locationObject;
      this.allStatesAndDistricts = response[0];
      console.log(locationObject, "selected");
      this.loader.hideLoader();

    }, (error) => {
      this.loader.hideLoader();
      this.toasterSevice.error("There was an error please try again later");
      console.log("Error from server", error);
    });

    const manufacturerCalls = this.sharedService.getAllMeterManufactures();
    forkJoin(manufacturerCalls).subscribe((response) => {
      let tabdataObject;
      let locationObject;
      this.allMeterManufactures = response[0];
      for (let i = 0; i < this.allMeterManufactures.data.length; i++) {
        this.meterManufactures.push({ value: this.allMeterManufactures.data[i].value, viewValue: this.allMeterManufactures.data[i].value })
      }

      console.log(locationObject, "selected");
      this.loader.hideLoader();
    }, (error) => {
      this.loader.hideLoader();
      this.toasterSevice.error("There was an error please try again later");
      console.log("Error from server", error);
    });
  }
  navigateback() {
    this.router.navigateByUrl('/orders');
  }
  validate(miuNumber) {
    this.loader.showLoader();
    let errorlog = true;
    const keys = Object.keys(miuNumber);
    keys.forEach((key, index) => {
      const miuSerial = miuNumber[key]
      if (!!miuSerial) {
        this.sharedService.fetchDetails(miuSerial, '60000000', 'miu').subscribe((res: any) => {
          if (res && res.equipmentData && res.equipmentData.length > 0) {
            res.equipmentData[0].returnStatuses.map(v => {
              if (res.equipmentData[0].returnStatuses.length > 0 && res.equipmentData[0].returnStatuses[0].returnStatusType == 'E' && res.equipmentData[0].returnStatuses[0].returnStatusDescription !== " Device/Connection Object Plant mismatch occured. Enter Valid MIU Serial Number") {
                this.invalidSerial.push(miuSerial);
              }
            })
            if (this.invalidSerial.indexOf(miuSerial) == -1) {
              this.validSerial.push(miuSerial);
            }
          }
          if (res.equipmentData === undefined && errorlog) {
            this.loader.hideLoader;
            errorlog = false;
            let alertObject = {
              type: "alert",
              title: "Error",
              message: "Unable to reach system. Retry validation.",
            };
            const dialogRef = this.dialog.open(AlertsComponent, {
              data: alertObject,
              hasBackdrop: true,
              disableClose: true,
              minWidth: "300px",
              maxWidth: "32vw"
            });
            dialogRef.afterClosed().subscribe(res => {
              this.loader.hideLoader();
            });

          }
          this.loader.hideLoader();
        },
        (error) => {
          this.loader.hideLoader;
          errorlog = false;
          let alertObject = {
            type: "alert",
            title: "Error",
            message: "Unable to reach system. Retry validation.",
          };
          const dialogRef = this.dialog.open(AlertsComponent, {
            data: alertObject,
            hasBackdrop: true,
            disableClose: true,
            minWidth: "300px",
            maxWidth: "32vw"
          });
          dialogRef.afterClosed().subscribe(res => {
            this.loader.hideLoader();
          });
        }
        );


      }
    });

  }

  validateMeters(meterNumbers) {
    this.invalidMeterSerial = [];
    this.validMeterSerial = [];
    this.loader.showLoader();
    let errorlog = true;
    let district = this.selectedDistrict;
    let manufacturer = this.manufacturerSelected;
    const keys = Object.keys(meterNumbers);
    keys.forEach((key, index) => {
      const meterSerial = meterNumbers[key]
      if (!!meterSerial) {
        this.sharedService.fetchDetails(meterSerial, '60000000', 'meter', district, manufacturer).subscribe((res: any) => {
          if (res && res.scannerData && res.scannerData.length > 0) {
            res.scannerData[0].returnStatuses.map(v => {
              if (res.scannerData[0].returnStatuses.length > 0 && res.scannerData[0].returnStatuses[0].returnStatusType == 'E') {
                const match = key.match(/\d+$/);
                if (match) {
                  const keyNumber = parseInt(match[0], 10);
                  this.invalidMeterSerial.push(meterSerial);
                  this.meterMap.set(keyNumber, ["", "Not Validated"]);
                }
              }
            })
            if (this.invalidMeterSerial.indexOf(meterSerial) == -1) {
              const match = key.match(/\d+$/);
              if (match) {
                const keyNumber = parseInt(match[0], 10);
                this.validMeterSerial.push(meterSerial);
                this.meterMap.set(keyNumber, [res.scannerData[0].planningPlant, ""])
              }
            }
          }
          if (res.scannerData === undefined && errorlog) {
            errorlog = false;
            let alertObject = {
              type: "alert",
              title: "Error",
              message: "Unable to reach system. Retry validation.",
            };
            const dialogRef = this.dialog.open(AlertsComponent, {
              data: alertObject,
              hasBackdrop: true,
              disableClose: true,
              minWidth: "300px",
              maxWidth: "32vw"
            });
            dialogRef.afterClosed().subscribe(res => {
              this.loader.hideLoader();
            });

          }
          this.loader.hideLoader();
        },
        (error) => {
          this.loader.hideLoader;
          errorlog = false;
          let alertObject = {
            type: "alert",
            title: "Error",
            message: "Unable to reach system. Retry validation.",
          };
          const dialogRef = this.dialog.open(AlertsComponent, {
            data: alertObject,
            hasBackdrop: true,
            disableClose: true,
            minWidth: "300px",
            maxWidth: "32vw"
          });
          dialogRef.afterClosed().subscribe(res => {
            this.loader.hideLoader();
          });
        }
        );
          
      }
    });
  }

  clearAll() {
    this.miuNumber = {};
    this.invalidSerial = [];
    this.validSerial = [];
  }

  clearAllMeter() {
    this.meterNumbers = {};
    this.invalidMeterSerial = [];
    this.validMeterSerial = [];
    for (let i = 0; i < this.fieldCount; i++) {
      this.meterMap.set(i, ["", "Enter Meter Number"]);
    }
  }

  alphaNumeric(event, index) {
    const key = event.keyCode;
    if (key == this.KEY_ENTER_ASCII_VAL) {
      let control = this.userInput;
      let nextindex = index + 1;
      let nextControl = control._results.filter(x => { return x.nativeElement.id == nextindex });
      if (nextControl != null) {
        nextControl[0]?.nativeElement.select();
      }
    }
    return ((key >= this.KEY_NUMBER_RANGE_MIN && key <= this.KEY_NUMBER_RANGE_MAX));
  }

  alphaNumericMeter(event, index) {
    const key = event.keyCode;
    if(key == this.KEY_ENTER_ASCII_VAL) {
      let control = this.userMeterInput;
      let nextindex = "meterInput" + (index + 1);
      let nextControl = control._results.filter(x => { return x.nativeElement.id == nextindex });
      if (nextControl != null) {
        nextControl[0]?.nativeElement.select();
      }
    }
    return ((key >= this.KEY_NUMBER_RANGE_MIN && key <= this.KEY_NUMBER_RANGE_MAX));
  }


  stateDistrictSelectionChange(district) {
    this.selectedDistrict = district;
    console.log(this.selectedDistrict);

  }

  isMeterArrayPopulated(): boolean {
    const keys = Object.keys(this.meterNumbers);
    let isValid = true;
    keys.forEach((key) => {
      if(this.meterNumbers[key].length > 0){
        isValid = false;
      }
    })
    return isValid;
  }

  isMIUArrayPopulated(): boolean {
    const keys = Object.keys(this.miuNumber);
    let isValid = true;
    keys.forEach((key) => {
      if(this.miuNumber[key].length > 0){
        isValid = false;
      }
    })
    return isValid;
  }

}